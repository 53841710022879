import service from "@/utils/request";


//获取搜索框列表
export const getPayTestSearchData = () => service({
    url: '/mchApp/getMchAppList',
    method: "get",
});

//获取应用数据   参数：appId
export const getPayTestAppData = (param) => service({
    url: '/payInterfaceConfig/getMchInterfaceList',
    method: "get",
    params: param,
});

//聚合支付提交
export const addPayTest = (data) => service({
    url: '/payExperience/mixPay',
    method: "post",
    data: data,
});

//动态码支付码获取
export const getDynamicQrCode = (data) => service({
    url: '/payExperience/getPayCode',
    method: "post",
    data: data,
    responseType: 'blob',
});

//静态码支付码获取
export const getStaticQrCode = (data) => service({
    url: '/payExperience/getStaticCode',
    method: "post",
    data: data,
    responseType: 'blob',
});
