<template>
  <div>
    <el-card shadow="never" class="card">
      <!-- 头部选择器 -->
      <el-select
        v-model="formData.mchAppId"
        placeholder="请选择"
        @change="selectChange"
      >
        <el-option
          v-for="item in options"
          :key="item.appId"
          :label="item.appName"
          :value="item.appId"
        >
        </el-option>
      </el-select>

      <!-- 支付方式 -->
      <hr v-if="appData.length != 0" />
      <div class="payMetheds" v-for="(item, index1) in appData" :key="index1">
        <h3 v-if="item.payChannel == 'WECHAT_PAY'">微信支付</h3>
        <h3 v-else-if="item.payChannel == 'ALI_PAY'">支付宝</h3>
        <h3 v-else>其他支付渠道</h3>
        <div class="payMetheds-box">
          <div
            class="payMetheds-box-item"
            @click="selectMethed(item.payChannel, i)"
            :class="
              isShow == item.payChannel + ' ' + i ? 'payMetheds-active' : ''
            "
            v-for="(i, index2) in item.payWayList"
            :key="index2"
          >
            <img
              class="img"
              :class="item.payChannel"
              src="~@/assets/images/payWay.png"
            />
            <span class="text">{{ i }}</span>
          </div>
        </div>
      </div>
      <div class="payMetheds" v-if="appData.length != 0">
        <h3>聚合支付</h3>
        <div class="payMetheds-box">
          <div
            class="payMetheds-box-item"
            @click="selectMethed('mixPay', 'dynamic')"
            :class="isShow == 'mixPay dynamic' ? 'payMetheds-active' : ''"
          >
            <img class="img qita" src="~@/assets/images/qrcode.png" />
            <span class="text">动态码支付</span>
          </div>
          <div
            class="payMetheds-box-item"
            @click="selectMethed('mixPay', 'static')"
            :class="isShow == 'mixPay static' ? 'payMetheds-active' : ''"
          >
            <img class="img qita" src="~@/assets/images/qrcode.png" />
            <span class="text">静态码支付</span>
          </div>
        </div>
      </div>

      <!-- 支付信息 -->
      <hr v-if="this.isShow != ''" />
      <div class="payInfo" v-if="this.isShow != ''">
        <h3>支付信息</h3>
        <div class="payInfo-box">
          <div class="payInfo-box-item">
            <div class="lable">订单编号：</div>
            <div>{{ orderNum == "" ? "————" : orderNum }}</div>
          </div>
          <div class="payInfo-box-item">
            <div class="lable">订单标题：</div>
            <el-input
              class="input"
              placeholder="请输入订单标题"
              v-model="formData.orderDetail"
              clearable
            >
            </el-input>
          </div>
          <div class="payInfo-box-item">
            <div class="lable">第三方用户关联ID：</div>
            <el-input
              class="input"
              placeholder="请输入第三方用户关联ID"
              v-model="formData.openId"
              clearable
            >
            </el-input>
          </div>
          <div class="payInfo-box-item" v-if="userRole">
            <div class="lable">商户号：</div>
            <el-input
              class="input"
              placeholder="请输入商户号"
              v-model="formData.mchNo"
              clearable
            >
            </el-input>
          </div>
          <div class="payInfo-box-item">
            <div class="lable">支付结果通知地址：</div>
            <el-input
              class="input"
              placeholder="请输入支付结果通知地址"
              v-model="formData.notifyUrl"
              clearable
            >
            </el-input>
          </div>
          <div class="payInfo-box-item">
            <div class="lable">支付金额(元)：</div>
            <el-radio-group
              v-model="formData.amount"
              @input="radioChange"
              class="payInfo-box-item-radio"
            >
              <el-radio :label="0.01">￥0.01</el-radio>
              <el-radio :label="0.21">￥0.21</el-radio>
              <el-radio :label="5.2">￥5.20</el-radio>
              <el-radio :label="0">自定义金额</el-radio>
              <el-tooltip
                content="最高自定义金额1000"
                placement="top"
                effect="light"
              >
                <el-input-number
                  v-show="showMoney"
                  size="small"
                  v-model="custom"
                  controls-position="right"
                  @change="handleChange"
                  :min="0.1"
                  :max="1000"
                ></el-input-number>
              </el-tooltip>
            </el-radio-group>
          </div>
        </div>
      </div>

      <!-- 支付按钮 -->
      <el-button
        type="primary"
        size="medium"
        @click="submit"
        v-if="this.isShow != ''"
        >立即支付</el-button
      >

      <!-- 支付二维码弹框 -->
      <el-dialog
        :visible.sync="payQrCodeVisitable"
        width="272px"
        center
        :show-close="true"
        class="dialog"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :before-close="payQrCodeCancel"
      >
        <img :src="qrCodeSrc" class="qrcode-img" />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {
  getPayTestSearchData,
  getPayTestAppData,
  addPayTest,
  getDynamicQrCode,
  getStaticQrCode,
} from "@/api/payTest/index";

export default {
  name: "payTest",
  data() {
    return {
      //权限
      userRole: false,
      //表单数据
      formData: {
        amount: 0.01,
        currency: "CNY",
      },
      //下拉列表数据
      options: [],
      //应用遍历数据
      appData: [],
      //订单编号
      orderNum: "",
      //选中支付方式
      isShow: "",
      //是否展示自定义金额
      showMoney: false,
      //自定义金额
      custom: undefined,
      //支付二维码弹框
      payQrCodeVisitable: false,
      //临时二维码url
      qrCodeSrc: "",
    };
  },

  mounted() {
    this.getSearchData();
    this.getUserRole();
  },

  methods: {
    //获取搜索框数据
    async getSearchData() {
      const res = await getPayTestSearchData();
      this.options = res.data;
    },

    //获取权限
    getUserRole() {
      const userRole = localStorage.getItem("userRole");
      console.log("sideNav页面：", userRole);
      if (userRole.includes("admin")) {
        this.userRole = true;
      }
    },

    //下拉框改变触发事件
    async selectChange(e) {
      const data = { appId: e };
      const res = await getPayTestAppData(data);
      let arr = res.data.map((item) => {
        return item.ifCode;
      });
      let ifCodeArr = [...new Set(arr)];
      let payStyleArr = ifCodeArr.map((ifCode) => {
        let obj = {
          payChannel: ifCode,
          payWayList: [],
        };
        return obj;
      });
      res.data.forEach((item) => {
        let index = ifCodeArr.indexOf(item.ifCode);
        payStyleArr[index].payWayList.push(item.wayCode);
      });
      this.appData = payStyleArr;
    },

    //选中应用方法点击事件
    selectMethed(ifCode, wayCode) {
      this.isShow = ifCode + " " + wayCode;
    },

    //单选框绑定值变化时触发事件
    radioChange(radio) {
      if (radio === 0) {
        this.showMoney = true;
      } else {
        this.custom = undefined;
        this.showMoney = false;
      }
    },

    //自定义金额数值改变触发事件
    handleChange(value) {
      this.custom = value;
    },

    //提交按钮
    async submit() {
      if (this.custom != undefined) {
        console.log(this.custom);
        this.formData.amount = this.custom;
      }
      console.log(this.formData.amount);
      this.formData.amount = this.formData.amount * 100;
      let arr = this.isShow.split(" ");
      this.formData.ifCode = arr[0];
      this.formData.wayCode = arr[1];
      let res;
      if (this.formData.wayCode == "dynamic") {
        delete this.formData.ifCode;
        delete this.formData.wayCode;
        res = await getDynamicQrCode(this.formData);
        this.createQrCodeSrc(res);
      } else if (this.formData.wayCode == "static") {
        delete this.formData.ifCode;
        delete this.formData.wayCode;
        res = await getStaticQrCode(this.formData);
        this.createQrCodeSrc(res);
      } else {
        res = await addPayTest(this.formData);
        console.log(res);
        this.orderNum = res.data.orderNo;
        window.open(res.data.data);
      }
    },

    // 转换数据流方法
    createQrCodeSrc(res) {
      if (res.size < 200) {
        this.$message.error("生成失败");
        return;
      }
      const blob = new Blob([res], { type: "image/png" });
      console.log(blob);
      let src = window.URL.createObjectURL(blob);
      console.log(src);
      this.qrCodeSrc = src;
      this.payQrCodeVisitable = true;
    },

    //支付二维码对话框关闭回调
    payQrCodeCancel(e) {
      this.payQrCodeVisitable = false;
    },
  },
};
</script>

<style scoped lang="less">
.card {
  hr {
    border: none;
    height: 1px;
    background-color: #e8e8e8;
    margin: 30px 0;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    color: #595959;
    margin-bottom: 12px;
  }

  /*支付方式 */
  .payMetheds {
    margin-bottom: 25px;

    .payMetheds-box {
      display: flex;

      .payMetheds-box-item {
        padding: 12px;
        border: 1px solid #e2e2e2;
        margin-right: 12px;

        .img {
          width: 40px;
          height: 40px;
          padding: 5px;
          border-radius: 12px;
          margin-right: 12px;
        }

        .text {
          color: #595959;
        }

        .WECHAT_PAY {
          background-color: #04c361;
        }

        .ALI_PAY {
          background-color: #1977fd;
        }

        .qita {
          background-color: #354268;
        }
      }

      .payMetheds-box-item:hover {
        cursor: pointer;
      }

      .payMetheds-active {
        border-color: #fd1919;

        .text {
          color: #fd1919 !important;
        }
      }
    }
  }

  /*支付信息 */
  .payInfo {
    .payInfo-box {
      .payInfo-box-item {
        display: flex;
        align-items: center;
        margin: 30px 0 20px 0;
        color: #595959;
        height: 32px;

        .lable {
          margin-right: 20px;
        }

        .refresh {
          margin-left: 10px;
          border: 1px solid #e2e2e2;
          padding: 1px 3px;
        }

        .input {
          width: 250px;
        }

        .payInfo-box-item-radio {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .dialog {
    :deep .el-dialog--center .el-dialog__body {
      padding: 12px 11px 8px;
    }

    :deep .el-dialog__header {
      padding: 10px;
    }

    .qrcode-img {
      width: 250px;
      height: 250px;
      background-color: #e46a6a;
    }
  }
}
</style>
